/* eslint-disable security/detect-object-injection */
import { ICoreContext, IGeneric, IAny, CultureInfoFormatter } from '@msdyn365-commerce/core';

import { AttributeValueBase, Customer, DiscountLine, ProductPrice, AttributeValue } from '@msdyn365-commerce/retail-proxy';
import React from 'react';

export interface IproductAttributesConfigArray {
    attributeName: string;
    attributeGlobalName: string;
    identifier: string;
}

export interface ICAttributeValue extends AttributeValue {
    identifier: string;
}

// VSI Customization for Currency formatter Funciton are created to get curreny with A$ sign
export const currencyFormat = (amount?: number, context?: ICoreContext<IGeneric<IAny>>): string | null => {
    const { isCustomCurrencySymbolEnabled, customCurrencySymbol } = context?.app.config;
    if (!amount || !context) {
        return null;
    }
    if (isCustomCurrencySymbolEnabled && customCurrencySymbol) {
        return `${customCurrencySymbol}${Number(amount)}`;
    }
    return context.cultureFormatter.formatCurrency(Number(amount));
};

// Set Order and Cart Attribues
export const setNameAttribute = (attrName: string, value: string): AttributeValueBase => {
    const privIdAttribute = {
        // @ts-ignore
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
        Name: attrName,
        ExtensionProperties: [],
        TextValue: value,
        TextValueTranslations: []
    };

    return privIdAttribute as AttributeValueBase;
};

// Find  attribute id exist update it other wise push

export const findAttribute = async (
    currentAttributes: AttributeValueBase[],
    attributeObj: AttributeValueBase
): Promise<AttributeValueBase[]> => {
    const attrIndex = currentAttributes.findIndex(
        (attr: AttributeValueBase) => attr.Name?.toLowerCase() === attributeObj.Name?.toLowerCase()
    );

    if (attrIndex > -1) {
        currentAttributes[attrIndex] = attributeObj;
    } else {
        currentAttributes.push(attributeObj);
    }

    return currentAttributes;
};

export const getAttributeValues = async (
    currentAttributes: AttributeValueBase[],
    attributeObj: AttributeValueBase
): Promise<AttributeValueBase[]> => {
    const attrIndex = currentAttributes.findIndex(
        (attr: AttributeValueBase) => attr.Name?.toLowerCase() === attributeObj.Name?.toLowerCase()
    );

    if (attrIndex > -1) {
        currentAttributes[attrIndex] = attributeObj;
    } else {
        currentAttributes.push(attributeObj);
    }

    return currentAttributes;
};

export function getOrderStatus(statusValue: Number | undefined) {
    if (statusValue === 0) {
        return 'Open Order';
    } else if (statusValue === 1) {
        return 'Processing';
    } else if (statusValue === 3) {
        return 'Shipped';
    } else if (statusValue === 4) {
        return 'Invoiced';
    } else if (statusValue === 7) {
        return 'Canceled';
    } else if (!statusValue) {
        return 'Open Order';
    } else {
        return 'Open Order';
    }
}

export const getDiscountType = (discountArray: DiscountLine[], discountType: string) => {
    return discountArray.find((discountLine: DiscountLine) => {
        if (discountLine.OfferName?.toLowerCase().trim() === discountType.toLowerCase().trim()) {
            return true;
        }
        return false;
    });
};

// tslint:disable

export const hasArray = (values: any): any[] | undefined => {
    return typeof values !== 'string' && values && values.length ? values : undefined;
};

export const incExcPriceFormat = (
    value: number,
    context?: ICoreContext<IGeneric<IAny>>,
    enabledIncExPrices?: boolean,
    inclusiveRate?: number,
    decimalPlaces?: number
): string | JSX.Element => {
    if (!value) {
        return <></>;
    }
    const getPrice = (inc?: number): string => {
        const taxAmount = (inc && value * (inc / 100)) || 0;
        const calculatedPrice = inc && taxAmount > 0 ? value + taxAmount : value;
        const formattedPrice =
            decimalPlaces && decimalPlaces > 0
                ? context?.cultureFormatter.formatCurrency(Number(calculatedPrice.toFixed(decimalPlaces)!))
                : String(currencyFormat(Number(calculatedPrice.toFixed(2)), context));
        return formattedPrice!;
    };

    const { currentRegionName, ounRegions } = context?.app.config;
    const currentRegion = ounRegions?.find((region: { Name: any }) => region.Name === currentRegionName);
    if (currentRegion && context?.request.apiSettings.oun === currentRegion?.oun) {
        //InCase of Mexico
        return <>{`${getPrice()} +IVA`}</>;
    } else if (enabledIncExPrices) {
        const exPriceText = `${getPrice()} Ex.`;
        const incPriceText = `(${getPrice(inclusiveRate || 0)} Inc.)`;
        return (
            <span className='krannich-price'>
                <span className='ex'>{exPriceText}</span>
                <span className='inc'>{incPriceText}</span>
            </span>
        );
    } else {
        return <>{getPrice()}</>;
    }
};

export const isRequestPrice = (price: ProductPrice, LineDiscountGroup: string, isBundle?: boolean): boolean => {
    // true is for request price
    // false is for show price

    // For Bundles
    if (isBundle) {
        return true;
    } else if (LineDiscountGroup === 'RoC' && price.CustomerContextualPrice === 0) {
        return true;
    } else if (LineDiscountGroup === '' && price.CustomerContextualPrice === 0) {
        return true;
    } else if (LineDiscountGroup !== '' && LineDiscountGroup !== 'RoC' && price.DiscountAmount === 0) {
        return true;
    } else {
        return false;
    }
};

export const convertDateFormatToMMDDYYY = (inputDate: string) => {
    if (!inputDate) {
        return null;
    }
    const [day, month, year] = inputDate.split('/');
    const originalDate = new Date(`${month}/${day}/${year}`);
    const newMonth = originalDate.getMonth() + 1;
    const newDay = originalDate.getDate();
    const newYear = originalDate.getFullYear();
    const outputDate = `${newMonth.toString().padStart(2, '0')}/${newDay.toString().padStart(2, '0')}/${newYear}`;
    return outputDate;
};

export const _calculateWpPrice = (
    prodAttributes: AttributeValue[],
    context: ICoreContext<IGeneric<IAny>>,
    isBundle: boolean | undefined,
    activePrice: ProductPrice,
    customerInformation: Customer
) => {
    if (!prodAttributes && !activePrice) {
        return null;
    }
    const customerTaxPercentage = taxPercentage(customerInformation);
    const { enabledIncExPrices } = context.app.config;
    const priceAttr = prodAttributes?.find(attr => attr.KeyName === 'PowerratingPmax(Wp)');
    const displayValueAttr = prodAttributes?.find(attr => attr.KeyName === 'DisplayWPPrice');
    const ppwPrice =
        context?.actionContext?.requestContext?.apiSettings?.oun !== context.app.config.ounNumberForUsSite ? 'WP Price:' : 'PPW Price:';

    if (priceAttr?.IntegerValue !== 0) {
        const price = priceAttr
            ? activePrice?.CustomerContextualPrice && activePrice?.CustomerContextualPrice / priceAttr?.IntegerValue!
            : null;
        const discountedWpPrice = priceAttr
            ? activePrice?.CustomerContextualPrice && activePrice?.CustomerContextualPrice / priceAttr?.IntegerValue!
            : null;
        const displayValue = displayValueAttr ? displayValueAttr.BooleanValue : false;

        if (
            displayValue &&
            price &&
            Number(priceAttr) !== 0 &&
            !isRequestPrice(activePrice, customerInformation?.LineDiscountGroup! ?? null, isBundle)
        ) {
            return (
                <div>
                    <div>
                        <span className='manufacturer-title'>{ppwPrice}</span>
                        {
                            <span className='manufacturer-name'>
                                {incExcPriceFormat(discountedWpPrice!, context, enabledIncExPrices, customerTaxPercentage, 3)}
                            </span>
                        }
                    </div>
                </div>
            );
        }
    }

    return null;
};

export const getMainDomain = (hostname: string): string => {
    const hostnameParts = hostname.split('.');
    const numParts = hostnameParts.length;
    let mainDomain = '';
    if (numParts === 2) {
        mainDomain = hostname;
    } else if (numParts > 2) {
        mainDomain = hostnameParts.slice(-2).join('.');
    }
    return mainDomain;
};

/**
 * Formats the URL path by combining the manufacturer and manufacturer item type name, and then replacing any invalid characters with hyphens.
 *
 * @param {string} manufacturer - the name of the manufacturer
 * @param {string} manufacturerItemType - the type name of the manufacturer item
 * @return {string} the formatted URL path
 */
export const formatUrlPath = (manufacturer: string, manufacturerItemType: string): string => {
    let urlPath = `${manufacturer}-${manufacturerItemType}`;
    urlPath = urlPath
        .replace(/[\s_]+|[^\p{L}\p{N}\-_]+/gu, '-')
        ?.replace(/-{2,}/g, '-')
        ?.replace(/-+/g, '-')
        ?.replace(/^-+|-+$/g, '');
    return urlPath?.toLowerCase();
};
export const filteredProductAttributeList = (
    mainProductAttributeArray: AttributeValue[] | undefined,
    productAttributeConfigArray: IproductAttributesConfigArray[]
) => {
    const filteredAttributes = productAttributeConfigArray.map(productAttribute => {
        const productAttr = mainProductAttributeArray?.find(mainProductAttribute => {
            return (
                mainProductAttribute.Name === productAttribute.attributeName ||
                mainProductAttribute.Name === productAttribute.attributeGlobalName
            );
        });
        const productAttrWithIdentifier = { ...productAttr, identifier: productAttribute.identifier };
        return productAttrWithIdentifier;
    });
    return filteredAttributes;
};
export const dateFormatByLocale = (locale: string, currency: string, date: string): string => {
    const [day, month, year] = date?.split('/');
    const originalDate = new Date(`${month}/${day}/${year}`);
    const cultureInfoFormatter = new CultureInfoFormatter(locale, currency);
    const newDate = cultureInfoFormatter.formatDate(originalDate);
    return newDate;
};

/**
 * Retrieves the tax percentage for a given customer.
 *
 * @param {Customer} customer - The customer object containing the tax information.
 * @return {number} The tax percentage value, or 0 if the tax value is undefined.
 */
export const taxPercentage = (customer: Customer): number => {
    const taxValue = customer?.Addresses?.find(address => address.IsPrimary === true)?.ExtensionProperties?.find(
        property => property.Key === 'TaxValue'
    )?.Value?.DecimalValue;
    return taxValue !== undefined ? taxValue : 0;
};
